import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('.application-form').on('submit-success', function(event, data) {
    $('.result').addClass('d-none');
    const type = data.prize ? 'instant' : 'loss';

    $(`.result-${data.prize ? 'success' : 'failed'}`).removeClass('d-none');
    if (data.prize) {
        $('.result-prize-name').html(data.prize.name);
        $('.result-form-link').attr('href', data.formUrl);
    }
    let modal = `#modal-form-application-${type}`
    apiPersonalData(data, modal);

    $(`#modal-form-application-${type}`).modal('show');
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    let modal = $('#modal-form-winner');

    apiPersonalData(data, '#modal-form-winner');

    modal.modal('show');

    $('.form-container').remove();
    $('.card').addClass('bg-white winner-info');
    $('.after-fill').removeClass('d-none');
    $('.bar').remove();
    $('.money').remove();
});

$('.contact-form').on('submit-success', function(event, data) {
    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-contact').modal('show');
});

function apiPersonalData(dataUser, modal) {
    $(`${modal} #data`).empty();
    dataUser.firstname && dataUser.lastname ? $(`${modal} #data`).append(`<p>Imię i nazwisko uczestnika: ${dataUser.firstname} ${dataUser.lastname}</p>`) : null;
    dataUser.email ? $(`${modal} #data`).append(`<p>E-mail uczestnika: ${dataUser.email}</p>`) : null;
    if (dataUser.billNr) {
        dataUser.billNr && dataUser.billPos ? $(`${modal} #data`).append(`<p>Numer paragonu: ${dataUser.billNr}</p>`) : $(`${modal} #data`).append(`<p>Numer faktury: ${dataUser.billNr}</p>`);
    }
    if (dataUser.billDate) {
        dataUser.billDate && dataUser.billPos ? $(`${modal} #data`).append(`<p>Data paragonu: ${dataUser.billDate}</p>`) :  $(`${modal} #data`).append(`<p>Data faktury: ${dataUser.billDate}</p>`);
    }
    dataUser.billNip ? $(`${modal} #data`).append(`<p>NIP sklepu: ${dataUser.billNip}</p>`) : null;
    dataUser.billPos ? $(`${modal} #data`).append(`<p>Unikalny numer kasy fiskalnej: ${dataUser.billPos}</p>`) : null;
    dataUser.birthDay ? $(`${modal} #data`).append(`<p>Data urodzenia: ${dataUser.birthDay}</p>`) : null;
    dataUser.address ? $(`${modal} #data`).append(`<p>Adres: ${dataUser.address}</p>`) : null;
    dataUser.citizenship ? $(`${modal} #data`).append(`<p>Narodowość: ${dataUser.citizenship}</p>`) : null;
    dataUser.pesel ? $(`${modal} #data`).append(`<p>Pesel: ${dataUser.pesel}</p>`) : null;
    dataUser.identityDocumentNr ? $(`${modal} #data`).append(`<p>Numer dowodu osobistego: ${dataUser.identityDocumentNr}</p>`) : null;
    dataUser.identityDocumentType ? $(`${modal} #data`).append(`<p>Typ dowodu osobistego: ${dataUser.identityDocumentType}</p>`) : null;
    dataUser.iban ? $(`${modal} #data`).append(`<p>Numer konta bankowego: ${dataUser.iban}</p>`) : null;
    dataUser.creditAccountNumber ? $(`${modal} #data`).append(`<p>Numer konta bankowego: ${dataUser.creditAccountNumber}</p>`) : null;
    // dataUser.uuid ? $(`${modal} #data`).append(`<p>UIC: ${dataUser.uuid}</p>`) : null;
    dataUser.pdf ? $(`${modal} #print`).attr('href', `${dataUser.pdf}`) : null;
    if (dataUser.confirmationUrl) {
        $(`${modal} hr`).removeClass(`d-none`);
        $(`${modal} img#confirmation`).removeClass(`d-none`);
        $(`${modal} img#confirmation`).attr(`src`, `${dataUser.confirmationUrl}`);
    }
    if (dataUser.paragonUrl) {
        $(`${modal} hr`).removeClass(`d-none`);
        $(`${modal} img#paragon`).removeClass(`d-none`);
        $(`${modal} img#paragon`).attr(`src`, `${dataUser.paragonUrl}`);
    }
    if (dataUser.agreementUrl) {
        $(`${modal} hr`).removeClass(`d-none`);
        $(`${modal} img#agreement`).removeClass(`d-none`);
        $(`${modal} img#agreement`).attr(`src`, `${dataUser.agreementUrl}`);
    }
}
